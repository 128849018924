<template>
  <div class="publicParams">
    <el-row>
      <el-col :span="2"> </el-col>
      <el-button class="iconfont icon-dcicon_save1" type="primary" @click="isSave ? save('form') : edit('form')">保存</el-button>
      <el-button class="iconfont icon-dcx" @click="quit()">取消</el-button>
    </el-row>
    <el-form ref="form" :model="form" label-width="100px">
      <baseSection name="参数信息">
        <el-row>
          <el-col :offset="0" :span="7">
            <el-form-item prop="name" label="参数名称:" :rules="[{ required: true, message: '请输入参数名称', trigger: 'blur' }]">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="7">
            <el-form-item prop="valueType" label="数据类型:" :rules="[{ required: true, message: '请选择参数类型', trigger: 'change' }]">
              <el-select v-model="form.valueType" placeholder="请选择数据类型" size="medium">
                <el-option label="bool" value="bool"></el-option>
                <el-option label="bit" value="bit"></el-option>
                <el-option label="string" value="string"></el-option>
                <el-option label="byte" value="byte"></el-option>
                <el-option label="short" value="short"></el-option>
                <el-option label="int" value="int"></el-option>
                <el-option label="long" value="long"></el-option>
                <el-option label="float" value="float"></el-option>
                <el-option label="double" value="double"></el-option>
                <el-option label="real" value="real"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :offset="1" :span="7">
            <el-form-item prop="code" label="参数代码:" :rules="[{ required: true, message: '请输入参数代码', trigger: 'blur' }]">
              <el-input v-model="form.code" placeholder="请输入参数代码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :offset="0" :span="7">
            <el-form-item prop="unit" label="参数单位:">
              <el-input v-model="form.unit" placeholder="请输入参数单位"></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="7" v-if="form.valueType !== 'string'">
            <el-form-item prop="isAlarm" label="报警参数:" :rules="[{ required: true, message: '请至少选择一个报警属性', trigger: 'change' }]">
              <el-radio-group v-model="form.isAlarm">
                <el-radio :label="0" :disabled="form.valueType === 'string'" v-model="form.isAlarm">非报警参数</el-radio>
                <el-radio :label="1" :disabled="form.valueType === 'string'" v-model="form.isAlarm">报警参数</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </baseSection>
      <baseSection name="上传资料">
        <el-row>
          <el-col :offset="0">
            <el-form-item label="参数图片">
              <Upload
                :setimgUrl="form.iconUrl"
                @getimgUrl="
                  item => {
                    form.iconUrl = item;
                  }
                "
                :currenttype="4"
                @geticonId="
                  item => {
                    form.iconId = item;
                  }
                "
              ></Upload>
            </el-form-item>
          </el-col>
        </el-row>
      </baseSection>
    </el-form>
    <Drawer
      :drawer.sync="drawer"
      @closeDrawer="closeDrawer"
      :title="'确认修改'"
      :AlertTitle="'修改内容将同步到以下设备模板和参数组'"
      :TemplateData="TemplateData.length ? TemplateData : null"
      :NestGroupData="NestGroupData.length ? NestGroupData : null"
      @clickbtn="update()"
    />
  </div>
</template>

<script>
import baseSection from '@/components/section/base-section';
import Drawer from '@/components/drawer/index.vue';
import Upload from '@/components/upload/uploadimg/index.vue';
export default {
  data() {
    return {
      // 表单校验
      drawer: false,
      // 模板引用数据
      TemplateData: [],
      // 嵌套组引用数据
      NestGroupData: [],
      form: {
        name: '',
        valueType: '',
        code: '',
        isAlarm: 0,
        unit: '',
        iconId: '',
        iconUrl: ''
      },
      isGroup: false
    };
  },
  components: {
    Upload,
    Drawer,
    baseSection
  },
  mounted() {
    if (this.$route.query.data === '[object Object]') {
      this.$router.go(-1);
      return false;
    }
    if (this.$route.query.data) {
      this.isGroup = this.$route.query.data.isGroup;
      this.isSave = false;
      this.form = this.$route.query.data;
    } else {
      this.isSave = true;
    }
  },

  methods: {
    async update() {
      const { paramId, name, valueType, code, isAlarm, unit, iconId, iconUrl } = this.form;
      const data = {
        groupId: 0,
        name,
        valueType,
        code,
        isAlarm: isAlarm - 0,
        unit,
        iconId,
        iconUrl,
        paramId
      };
      if (localStorage.getItem('isGroup') === 'group') {
        data.groupId = JSON.parse(localStorage.getItem('this_basicGroup')).groupId;
      }
      const { fail } = await this.$apis.publicParams.update(data);
      if (!fail) {
        this.$router.go(-1);
      }
    },
    edit(form) {
      let arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      let code = this.form.code[0] - 0;
      let isNumber = false;
      for (let i = 0; i < arr.length; i++) {
        if (code === arr[i]) {
          isNumber = true;
        }
      }
      if (isNumber) {
        this.$message.warning('代码不能以数字开头');
        return false;
      }
      if (this.form.valueType === 'string') {
        this.form.isAlarm = 0;
      }
      this.$refs[form].validate(async valid => {
        if (valid) {
          if (this.isGroup) {
            const res = await this.$apis.basicParamsGroup.previewCite({ groupId: JSON.parse(localStorage.getItem('this_basicGroup')).groupId });
            if (res.deviceTemplateVoList.length === 0 && res.deviceParamGroupVoList.length === 0) {
              this.update();
            } else {
              this.TemplateData = res.deviceTemplateVoList;
              this.NestGroupData = res.deviceParamGroupVoList;
              this.drawer = true;
            }
          } else {
            const res = await this.$apis.publicParams.previewCite({ paramIds: this.form.paramId, type: 1 });
            if (res.deviceTemplateVoList.length === 0 && res.deviceParamGroupVoList.length === 0) {
              this.update();
            } else {
              this.TemplateData = res.deviceTemplateVoList;
              this.NestGroupData = res.deviceParamGroupVoList;
              this.drawer = true;
            }
          }
        } else {
          this.$message.error('请正确填写参数信息');
          return false;
        }
      });
    },
    async save(form) {
      let arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      let code = this.form.code[0] - 0;
      let isNumber = false;
      for (let i = 0; i < arr.length; i++) {
        if (code === arr[i]) {
          isNumber = true;
        }
      }
      if (isNumber) {
        this.$message.warning('代码不能以数字开头');
        return false;
      }
      if (this.form.valueType === 'string') {
        this.form.isAlarm = 0;
      }
      this.$refs[form].validate(async valid => {
        if (valid) {
          const { name, valueType, code, isAlarm, unit, iconId, iconUrl } = this.form;
          const data = {
            groupId: 0,
            name,
            valueType,
            code,
            isAlarm: isAlarm - 0,
            unit,
            iconId,
            iconUrl
          };
          if (localStorage.getItem('isGroup') === 'group') {
            data.groupId = JSON.parse(localStorage.getItem('this_basicGroup')).groupId;
          }
          const { fail } = await this.$apis.publicParams.save(data);
          if (!fail) {
            this.$router.go(-1);
          }
        } else {
          this.$message.error('请正确填写参数信息');
          return false;
        }
      });
    },
    quit() {
      this.$refs.form.resetFields();
      this.$refs.form.clearValidate();
      this.$router.go(-1);
    },
    closeDrawer() {
      this.drawer = false;
    }
  }
};
</script>
<style>
.el-row {
  margin-bottom: 20px;
}
.publicParams {
  overflow: hidden;
}
.el-input,
.el-select {
  width: 100%;
}
</style>
